.friend-item{
    display: flex;
    justify-content: center;
}
.friend-name{
    color: hotpink;
    font-size: 10px;
    width: 20%;
}
/* .friend-name:hover{
    cursor: pointer;
} */
