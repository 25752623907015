.cart-button{
    border-radius: 4px;
    border: none;
    padding: 1px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    text-decoration: none !important;
    color: #D2E885 !important;
    background-color: rgba(121,153,5,1);
    width: 156px;
    height: 32px;

}
.cart-button:hover{
    color: rgb(137, 113, 133) !important;

}
.game-detail-container{
    display: flex;
    justify-content: center;
    height: 100%;
    margin-left: 10%;
    margin-right: 10%;
}
.game-images-container{
    display: flex;
    gap: 5%;
    width: 75%;
}
.game-title{
    font-weight: bold;
    font-size: xx-large;
    color: #d6d7d8;

}
.buy-item-container{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: rgb(67,82,96);
    width: auto;
    height: 55px;
}
.game-buy-title{
    font-weight: bold;
    font-size: large;
    background-color: rgb(67,82,96);
    color: #d6d7d8;
}
