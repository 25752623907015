.login-button {
    position: relative;
    background: linear-gradient(90deg, #06BFFF 0%, #2D73FF 100%);
    border-radius: 2px;
    border: none;
    outline: none;
    padding: 12px;
    color: #fff;
    font-size: 18px;
    font-weight: 500;

    text-align: center;
    letter-spacing: .03em;
    cursor: pointer;
    width: 40%;
    margin-top: 5px;
}

.login-button-disabled {
    position: relative;
    background: linear-gradient(90deg, #06BFFF 0%, #2D73FF 100%);
    border-radius: 2px;
    border: none;
    outline: none;
    padding: 12px;
    color: #fff;
    font-size: 18px;
    font-weight: 500;

    text-align: center;
    letter-spacing: .03em;
    cursor: not-allowed;
    width: 40%;
    margin-top: 5px;

}

.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-form-page {
    display: flex;
    justify-content: center;

}

.login-container {
    /* background-color: #181a21; */
    border-radius: 4px;
    padding: 24px 32px;
    flex: 1;
    min-width: 636px;
    max-width: 450px;
}

.errors {
    color: red;
}

.error {
    color: red;
}

.input-field {
    border: 1px solid darkgray;
    padding: 10px;
    border-radius: 9px;
}
